import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import {
  AccessButton,
  ExternalLink,
  SectionBox,
  SeparatorLine,
  Subtitle,
} from '../base'

export function PixieDust(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      MerchantsFist: file(relativePath: { eq: "images/MerchantsFist.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionBox bg="primary.main">
      <Box pb={[4, 5, 5]} pt={[4, 5, 1]}>
        <Box
          width={[1 / 9, 1 / 8, 1 / 20]}
          sx={{ alignSelf: 'start' }}
          pb={4}
          pt={[0, 0, 4]}
        >
          <Img fluid={data.MerchantsFist.childImageSharp.fluid} />
        </Box>
        <Subtitle pb={4} fontWeight="black" fontSize={[4, 6, 13]}>
          Tell us how we can improve
        </Subtitle>
        <Subtitle fontWeight="black" fontSize={[2, 6, 12]}>
          Your feedback is magic pixie gold dust
        </Subtitle>
      </Box>
      <Box py={[10, 0, 0]} px={[4, 4, 7]} textAlign="center" pb={[4, 6, 6]}>
        <Subtitle fontSize={2} color="primary.text" py={4}>
          Click this to access the choice feedback form
        </Subtitle>
        <AccessButton
          width={[2 / 3, 1 / 2, 1 / 5]}
          sx={{ borderRadius: 20 }}
          bg="primary.text"
        >
          <ExternalLink href="https://choicetopay.typeform.com/to/PXRSmF">
            <Text color="primary.main">Feedback form</Text>
          </ExternalLink>
        </AccessButton>
      </Box>
      <SeparatorLine color="white" />
    </SectionBox>
  )
}

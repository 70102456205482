import React from 'react'
import { withLayout } from '../components/Layout'
import { Container } from '../components/base'
import { PixieDust } from '../components/feedback/PixieDust'

const FeedbackPage = () => {
  return (
    <Container>
      <PixieDust />
    </Container>
  )
}

export default withLayout(FeedbackPage)
